export const getCountryNameByCode = (
	countryCode?: string,
): string | undefined => {
	if (!countryCode) return undefined
	const countryCodeToLower = countryCode.toLowerCase()
	const countries: { [key: string]: string } = {
		en: 'England',
		se: 'Sverige',
		dk: 'Danmark',
		no: 'Norge',
		fi: 'Finland',
	}
	return countries[countryCodeToLower.toLowerCase()]
}
